class ProductSessionTypesController {
    constructor(DriveSessionTypesModel){
        this.sessiontypes = [];
        this.sessiontype = {};
        this.observation = false;
        this.hours = null;
        DriveSessionTypesModel.get().then( response => {
            this.sessiontypes = response.sessionTypes;
            if (this.sessiontypes.length > 0) {
            	this.sessiontype = this.sessiontypes[0];
            }
        })
    }

    addSessionTypeToProduct(){
        this.sessiontype.hours = this.hours * 60;
        this.sessiontype.observation = this.observation;
        this.product.sessiontypes.push(Object.assign({}, this.sessiontype));
        this.sessiontype.hours = null;
    }

    removeSessionType(index){
        this.product.sessiontypes.splice(index, 1);
    }
}
ProductSessionTypesController.$inject = ['DriveSessionTypesModel'];
export default ProductSessionTypesController;
