import { States } from '../../../common/constants/states'
import { createBillingGatewayFromPolicies} from '../../../classes/billing/BillingGateway'
import {PaymentMethods} from "../../../common/constants/payment_methods";
import {Months} from "../../../common/constants/months_and_years";
import {Years} from "../../../common/constants/months_and_years";
import cloneDeep from 'lodash/cloneDeep'
import { Coupon } from '../../../classes/Coupon';
import { inDollars } from '../../../../DriveScout/Billing/Teller';
import { Student } from '../../../classes/Student';
import { Product } from '../../../classes/Product';
import { Address } from '../../../classes/Address';
import { CreditCard } from '../../../classes/CreditCard';

class dsStudentOrderFormController {

    constructor($scope, $location, UsersModel, FlashService, UserService, OrdersModel, PayPalService, CreateOrderService, CouponsModel){
        this.PayPalService = PayPalService;
        this.UsersModel = UsersModel;
        this.OrdersModel = OrdersModel;
        this.CouponsModel = CouponsModel;
        this.FlashService = FlashService;
        this.billingAddressDoesNotAlreadyExist = false;
        this.proceedToPayment = this.proceedToPayment.bind(this);
        this.showUseExistingPaymentMethodUI = false;

        this.student = UserService.getCurrentUser();
        this.returnUrl = $location.absUrl() + '?student_id=' + this.student.id;

        this.inDollars = inDollars.bind(this);
        this.setStudentCart = this.setStudentCart.bind(this);
        this.handleAuthorizeCard = this.handleAuthorizeCard.bind(this);
        this.handleCompletedConfirmationToken = this.handleCompletedConfirmationToken.bind(this);
        this.tokenizationSupported = this.tokenizationSupported.bind(this);

        const $cashier = CreateOrderService.cashier();

        this.$cashier = $cashier;
        this.addresses = this.$cashier.addresses();
        this.billingAddress = {
            street: "",
            city: "",
            state: "",
            zip: ""
        }

        const policies = this.$cashier.policies();
        this.gateway = policies.getPolicyByName('billing').getMetaValueByKey('billing_gateway');
        this.gateway_config = createBillingGatewayFromPolicies(policies).config();

        this.surchargeBilledSeparately = this.$cashier.surchargeBilledSeparately();
        this.surchargeAmount = this.$cashier.surchargeAmount();

        this.surchargeDetail = this.$cashier.surchargeDetail();

        if (this.$cashier.order().hasStudent() === true && this.$cashier.order().student().$originalData.guardian) {
            const $payments = this.$cashier.order().payments().map(payment => {
                payment.creditCard().setName(this.$cashier.order().student().$originalData.guardian.firstname + ' ' + this.$cashier.order().student().$originalData.guardian.lastname)
                return payment;
            });
            this.$cashier.order().setPayments($payments);
        }

        if (this.$cashier.order().hasStudent() === true && ! this.$cashier.order().student().$originalData.guardian) {
            const $payments = this.$cashier.order().payments().map(payment => {
                payment.creditCard().setName(this.$cashier.order().student().firstname() + ' ' + this.$cashier.order().student().lastname())
                return payment;
            })
            this.$cashier.order().setPayments($payments)
        }

        this.payment_methods = cloneDeep(PaymentMethods);
        this.months = Months;
        this.years = Years;
        this.states = States;

        if (this.gateway === "stripe") {
            this.stripeElementOptions = {
                currency: 'usd',
                amount: undefined,
            }
        }
    }

    tokenizationSupported()
    {
        if (this.gateway === "authorize" && this.gateway_config.authorize_api_client_key) {
            return true;
        }

        if (this.gateway === "stripe") {
            return true;
        }

        return false;
    }

    toggleUseExistingPaymentMethod()
    {
        this.showUseExistingPaymentMethodUI = !this.showUseExistingPaymentMethodUI;

        if (this.showUseExistingPaymentMethodUI === true) {
            delete this.$cashier.$order.$payments[0].$card.$payment_method_id;
            delete this.stripeElementOptions.amount;
        }
    }

    proceedToPayment(amount){
        delete this.$cashier.$order.$payments[0].$card.$payment_method_id;
        this.showUseExistingPaymentMethodUI = false;
        this.$cashier.$order.$payments[0].$amount = amount;
        this.stripeElementOptions.amount = amount;
    }

    buttonText()
    {
        let msg = "Submit Order";
        if (this.$cashier.order().payments()[0].amount()) {
            msg += " and Payment for " + this.$cashier.amountPaidToday();
        }
        return msg;
    }

    removeCoupon()
    {
        this.$cashier.removeCoupon();
        this.$cashier.calculateTotal();
        this.updateAmountPaid();
    }

    updateAmountPaid()
    {
        if (this.$cashier.allowsPartialPayments() === false) {
            this.$cashier.order().payments()[0].setAmount(this.$cashier.totalDue());
        }
    }

    submitCouponCode()
    {
        const code = this.coupon_code;
        this.CouponsModel.validateCoupon(code).then(response => {
            const coupon = new Coupon(response.coupon)
            this.$cashier.addCoupon(coupon)
            delete this.coupon_code
            this.FlashService.setMessage({
                'message' : response.message,
                'type' : 'success'
            })
            this.updateAmountPaid()
        })
    }

    handleAmountChange(){
        // this.checkForOverpayment();
        this.$cashier.calculateTotal();
    }

    removeProductFromOrder(product) {
        this.$cashier.removeProduct(product);
        this.updateAmountPaid()
        this.$cashier.calculateTotal();
    }

    /**
     * If the billing address is already an available address that means its a pick-up location. Students cannot have
     * duplicate pick-up locations, so prevent the admin from being able to select the option to add it as a
     * pick-up location
     *
     * @param billingAddress
     * @returns {void}
     */
    checkBillingAddressAlreadyExistsAsPickupLocation(billingAddress = '') {
        if (!billingAddress) {
            return false;
        }

        let addressExists = this.$cashier.addresses().filter(address => {
            return address.street().toLowerCase() === billingAddress.toLowerCase()
        }).length > 0;
        if (!addressExists) {
            this.billingAddressDoesNotAlreadyExist = true;
            this.$cashier.order().$payments[0].$add_billing_address_as_pickup_location = false;
        } else {
            this.billingAddressDoesNotAlreadyExist = false;
            this.$cashier.order().$payments[0].$add_billing_address_as_pickup_location = true;
        }
    }

    updateBillingAddressesList(){
        this.UsersModel.getPickupLocations(this.$cashier.order().student().id()).then(response => {
            this.addresses = response.pickuplocations.map(location => {
                return location.address;
            });
        })

    }

    /**
     * Add a billing address to the order
     * @param {number} index 
     * @param {Address} address 
     */
    addBillingAddress(index, address){
        this.billingAddress = {
            street: address.street(),
            city: address.city(),
            state: address.region(),
            zip: address.postalCode()
        }
        this.$cashier.addBillingAddress(index, address);
        this.$cashier.calculateTotal();
        this.checkBillingAddressAlreadyExistsAsPickupLocation(address.street())
    }
    
    selectPaymentMethod(id, paymentIndex) {
        this.$cashier.setCustomerPaymentMethod(id, paymentIndex);
    }

    setStudentCart()
    {
        let payload = {
            products: [].concat(this.$cashier.order().products().map(product => {
                return { id: product.id() }
            })),
            payment_amount: this.$cashier.$order.$payments[0].$amount,
            payment_method: this.$cashier.$order.$payments[0].$payment_method,
        }
        if (this.$cashier.order().payments()[0].creditCard().billingAddress().street()) {
            payload.billing_address = {
                street: this.$cashier.order().payments()[0].creditCard().billingAddress().street(),
                city: this.$cashier.order().payments()[0].creditCard().billingAddress().city(),
                state: this.$cashier.order().payments()[0].creditCard().billingAddress().region(),
                zip: this.$cashier.order().payments()[0].creditCard().billingAddress().postalCode(),
            }
        }
        if (this.$cashier.order().coupon() != null) {
            payload.coupon = {
                id: this.$cashier.order().coupon().id()
            }
        }
        return this.UsersModel.setStudentCart(this.$cashier.order().student().id(), payload).then(response => {
            return response;
        }).catch(Error => {
            console.error(Error)
        })
    }

    handleAuthorizeCard(tokenData)
    {
        let valid = this.$cashier.order().products().length === 0 || !this.$cashier.order().student() || this.$cashier.order().submitted === true;
        if (!valid) {
            // fail
        }

        if (!this.gateway_config.authorize_api_client_key) {
            const card = new CreditCard(tokenData);
            if (tokenData.billing_address) {
                const address = new Address(
                    0,
                    "",
                    tokenData.billing_address.street,
                    tokenData.billing_address.city,
                    tokenData.billing_address.state,
                    tokenData.billing_address.zip,
                    0,
                    0
                );
                card.setBillingAddress(address);
            }
            this.$cashier.order().payments()[0].$card = card;
        } else {
            // set token data on order
            this.$cashier.order().$payments = this.$cashier.order().payments().map(payment => {
                if (payment.paymentMethod() === 'card') {
                    payment.creditCard().tokenize({opaqueData: tokenData});
                }
                return payment;
            });
        }

        this.submit(this.$cashier.order());
    }

    async handleCompletedConfirmationToken(token)
    {
        let { cart } = await this.UsersModel.getStudentCart(this.$cashier.order().student().id());
        let {products, coupon, student, billing_address, payment_amount, payment_method} = cart;
        const $products = products.map(p => new Product(p));
        const $student = new Student(student);
        this.$cashier.order().setProducts($products);
        this.$cashier.order().setStudent($student);
        if (coupon) {
            const $coupon = new Coupon(coupon);
            this.$cashier.order().setCoupon($coupon);
        }

        if (billing_address) {
            const address = new Address(0, "", billing_address.street, billing_address.city, billing_address.state, billing_address.zip, 0, 0);
            this.$cashier.$order.$payments[0].$card.$billing_address = address;
        }

        this.$cashier.$order.$payments[0].$amount = payment_amount;
        this.$cashier.$order.$payments[0].$payment_method = payment_method;

        // set token data on order
        this.$cashier.order().$payments = this.$cashier.order().payments().map(payment => {
            if (payment.paymentMethod() === 'card') {
                payment.creditCard().tokenize(token);
            }
            return payment;
        });

        this.submit(this.$cashier.order());
        return true;
    }

}
dsStudentOrderFormController.$inject = ['$scope', '$location', 'UsersModel', 'FlashService', 'UserService', 'OrdersModel', 'PayPalService', 'CreateOrderService', 'CouponsModel'];
export default dsStudentOrderFormController;
