
class AdminStudentViewFeesController {
  constructor($stateParams, UsersModel, FlashService) {
    this.loading = true;
    this.$stateParams = $stateParams;
    this.UsersModel = UsersModel;
    this.FlashService = FlashService;
    this.fees = [];

    UsersModel.getStudentByID($stateParams.userId).then(response => {
      this.student = response;
    });

    this.UsersModel.getStudentFees(this.$stateParams.userId).then(response => {
      this.fees = response.fees;
      this.loading = false;
    });
  }

  voidFee(feeId, index) {
    this.submitted = true;
    this.UsersModel.voidFee(this.student.id, feeId).then(response => {
      this.submitted = false;
      this.FlashService.setMessage({
        type: "success",
        message: response.message
      });
      response.fee.amount_owed = 0;
      this.fees[index] = response.fee;
    });
  }
  
}
AdminStudentViewFeesController.$inject = [
  "$stateParams",
  "UsersModel",
  "FlashService",
];
export default AdminStudentViewFeesController;
