import angular from 'angular'
import controller from './student-fees.controller'
import template from './student-fees.html'

import StudentPayFee from './pay/student-pay-fee.module'
function config($stateProvider, $urlRouterProvider, USER_ROLES) {
    $stateProvider
        .state('drivecoach.student.fees', {
            'url': '/fees',
            'controller': controller,
            'controllerAs': 'vm',
            'template': template,
            data: {
                authorizedRoles: [
                    USER_ROLES.admin,
                    USER_ROLES.administrator,
                    USER_ROLES.owner,
                    USER_ROLES.student
                ]
            }
        })
        ;
}
config.$inject = ['$stateProvider', '$urlRouterProvider', 'USER_ROLES'];

export default angular.module('student.fees', [
    StudentPayFee.name
])
    .config(config)
    .controller('StudentFeesController', controller);
