import {Order} from '../../classes/Order';
import {Product} from '../../classes/Product'
import {Address} from '../../classes/Address'
import {Student} from '../../classes/Student';
import {isJson} from '../../common/helpers/json'
import {Service} from '../../classes/Service';
import {cashierOrderRequestTransformer} from '../../classes/Cashier';

class StudentOrdersCreateController {

    constructor($state, $stateParams, UsersModel, FlashService, UserService, OrdersModel, ProductsModel, $q, CreateOrderService){
        
        const $order = new Order();
        const $student = new Student(UserService.getCurrentUser());
        $order.setStudent($student);
        CreateOrderService.cashier().setOrder($order);
        this.CreateOrderService = CreateOrderService;
        this.loading = false;
        this.$state = $state;
        this.student = UserService.getCurrentUser();
        this.FlashService = FlashService;
        this.OrdersModel = OrdersModel;
        this.UsersModel = UsersModel;
        this.product = null;
        this.products = [];
        this.addresses = [];
        this.$cashier = this.CreateOrderService.cashier();
        
        this.loading = false;
        this.submitting = false;
        this.createOrder = this.createOrder.bind(this);
        let getBillingAddresses = this.UsersModel.getPickupLocations(this.student.id)
        let getProducts = ProductsModel.get({'paging' : false, 'disabled' : false, 'purchasable' : true});
        let getPaymentMethods = this.UsersModel.getStudentPaymentMethods(this.student.id);

        $q.all([getBillingAddresses, getPaymentMethods, getProducts]).then(responses => {
            this.addresses = responses[0].pickuplocations.map(location => {
                let address = isJson(location.address) ? JSON.parse(location.address) : location.address
                return new Address(
                    location.id, location.name, address.street, address.city, address.state, address.zip, address.lat, address.lng
                );
            });
            this.$cashier.setStudentAddresses(this.addresses);

            let paymentMethods = responses[1].payment_methods;
            this.$cashier.setStudentPaymentMethods(paymentMethods);

            this.products = responses[2].products.data.map(product => {
                const $product = new Product(product);
                const $services = product.sessiontypes.map(service => {
                    return new Service(service);
                })
                $product.setServices($services);
                return $product;
            });
            this.loading = false;
        })

    }

    reset(){
        const order = new Order();
        const student = new Student(this.student);
        order.setStudent(student);
        this.$cashier.$use_existing_payment_method = false;
        this.$cashier.setOrder(order);
        this.loading = false;
        this.submitting = false;
    }

    createOrder(order){
        this.submitting = true;
        if (this.$cashier.validateMinimumPayment() === false) {
            this.FlashService.setMessage({
                'type': 'warning',
                'message': 'You must pay at least $' + parseFloat(this.$cashier.minimumDepositAmountRequired() / 100).toFixed(2)
            });
        } else {
            let data = cashierOrderRequestTransformer(this.$cashier);
            this.OrdersModel.create(data).then(response => {
                this.FlashService.setMessage({
                    'type': 'success',
                    'message': response.message
                });
                this.$state.go('drivecoach.student.orders');
                this.reset();
            }).catch(Error => {
                order.submitted = false;
            })
        }
        
    }

    addProductToOrder(){
        this.$cashier.addProduct(this.product);
        this.product = null;
        if (this.$cashier.allowsPartialPayments() === false) {
            this.$cashier.order().payments()[0].setAmount(this.$cashier.totalDue());
        }
        this.$cashier.calculateTotal();
    }

}
StudentOrdersCreateController.$inject = ['$state', '$stateParams', 'UsersModel', 'FlashService', 'UserService', 'OrdersModel', 'ProductsModel', '$q', 'CreateOrderService'];
export default StudentOrdersCreateController;
