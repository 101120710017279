import moment from 'moment'
class AddStudentModalInstanceCtrl {

    constructor($scope, $uibModalInstance, drivesession, UsersModel, FlashService, toggleStudentSchoolOverride){
        this.$scope = $scope;
        this.$uibModalInstance = $uibModalInstance;
        this.searchingStudents = false;
        this.toggleStudentSchoolOverride = toggleStudentSchoolOverride;
        this.drivesession = drivesession;
        this.UsersModel = UsersModel;
        this.FlashService = FlashService;
        this.student = null;
        this.studentsOnSession = [];
        this.students = [];
        this.observer_or_driver = '';

        if (drivesession.hasOwnProperty('student') && drivesession.student) {
            this.studentsOnSession.push(drivesession.student);
        }

        if (this.drivesession.observation === true && ! this.drivesession.students) {
            this.drivesession.students = [];
        }

        if (this.drivesession.hasOwnProperty('students') && this.drivesession.students.length > 0) {
            this.drivesession.students.forEach(student => {
                this.studentsOnSession.push(student);
            });
        }
    }

    /**
     * Search for students by name
     * @param search
     * @param all
     */
    searchStudents(search, all) {

        if(this.searchingStudents === true){
            return;
        }

        let searchParams = {};
        this.searchingStudents = false;
        this.FlashService.clearFlash();

        if (all === false) {
            searchParams = {
                'hours': ( parseInt(this.drivesession.sessionLengthHours) * 60 ) + parseInt(this.drivesession.sessionLengthMinutes),
                'date': {
                    'date': moment(this.drivesession.drive_date).format('YYYY-MM-DD'),
                    'time': moment(this.drivesession.start_time).format('HH:mm:ss'),
                    'duration': ( parseInt(this.drivesession.sessionLengthHours) * 60 ) + parseInt(this.drivesession.sessionLengthMinutes)
                }
            };
        }

        if (search && search.length >= 3 && !this.searchingStudents) {
            searchParams.name = search;
        }

        // searchParams.drive = this.drivesession;

        searchParams.exclude = {
            'drives': [{'id': this.drivesession.id}]
        };

        if (typeof searchParams.name === 'undefined') {
            return;
        }

        searchParams.toggleStudentSchoolOverride = this.toggleStudentSchoolOverride;

        this.searchingStudents = true;
        this.UsersModel.searchStudents(searchParams).then(response => {
            this.students = response.students.map(student => {
                return student;
            });
            this.searchingStudents = false;
        });
    }

    addStudentToSession(){
        this.FlashService.clearFlash();
        // prevent from adding if student is already on session
        let sessionHasStudent = this.studentsOnSession.filter( student => student.id === this.student.id );
        if(sessionHasStudent.length > 0){
            return false;
        }

        // Is this drive session an observation?
        // If it is not, then just add the student
        if (this.drivesession.observation === false) {
            let student = Object.assign({}, this.student);
            student.saved = false;
            this.drivesession.student = student;
            this.drivesession.status = 'Taken';
            this.drivesession.pickuplocation = this.drivesession.student.pickuplocations[0];
            this.drivesession.dropofflocation = this.drivesession.student.pickuplocations[0];
        } else {
            let student = Object.assign({}, this.student);
            student.saved = false;
            student.observer_or_driver = this.observer_or_driver;
            this.drivesession.students.push(student);
            this.drivesession.status = 'Taken';
        }

        return true;
    }

    ok() {
        let addStudent = this.addStudentToSession();
        if( addStudent === true ) {
            this.$uibModalInstance.close({
                'drivesession': this.drivesession,
                'toggleStudentSchoolOverride': this.toggleStudentSchoolOverride
            });
        }else{
           this.FlashService.setMessage({
               'type' : 'warning',
               'message' : 'This student has already been booked with this session'
           })
        }
    }

    cancel() {
        this.$uibModalInstance.dismiss('cancel');
    }
}
AddStudentModalInstanceCtrl.$inject = ['$scope', '$uibModalInstance', 'drivesession', 'UsersModel', 'FlashService', 'toggleStudentSchoolOverride'];

export default AddStudentModalInstanceCtrl;
