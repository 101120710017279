"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var BillingGateway_1 = require("../../classes/billing/BillingGateway");
var months_and_years_1 = require("../../common/constants/months_and_years");
var states_1 = require("../../common/constants/states");
var StudentPaymentMethodsController = /** @class */ (function () {
    function StudentPaymentMethodsController($stateParams, UsersModel, PoliciesModel, PolicyService, FlashService, UserService, $location) {
        var _this = this;
        this.$stateParams = $stateParams;
        this.$location = $location;
        this.loading = true;
        this.UsersModel = UsersModel;
        this.PoliciesModel = PoliciesModel;
        this.PolicyService = PolicyService;
        this.FlashService = FlashService;
        this.paymentMethods = [];
        this.gateway = 'none';
        this.gateway_config = {};
        this.years = months_and_years_1.Years;
        this.months = months_and_years_1.Months;
        this.states = states_1.States;
        this.handleAuthorizeCard = this.handleAuthorizeCard.bind(this);
        this.tokenizationSupported = this.tokenizationSupported.bind(this);
        this.billing_address = {
            street: '',
            city: '',
            state: '',
            zip: ''
        };
        this.returnUrl = $location.absUrl();
        this.student = UserService.getCurrentUser();
        UsersModel.getStudentByID(this.student.id).then(function (response) {
            _this.student = response;
        });
        var policies = PolicyService.getPolicies();
        var billingPolicy = policies.getPolicyByName('billing');
        if (billingPolicy.metaExistsByKey('billing_gateway')) {
            this.gateway = billingPolicy.getMetaValueByKey('billing_gateway');
            this.gateway_config = (0, BillingGateway_1.createBillingGatewayFromPolicies)(policies).config();
            if (this.gateway === 'stripe') {
                this.getSetupIntent();
            }
        }
        UsersModel.getStudentPaymentMethods(this.student.id).then(function (response) {
            _this.paymentMethods = response.payment_methods;
            _this.loading = false;
        });
    }
    StudentPaymentMethodsController.prototype.getSetupIntent = function () {
        var _this = this;
        this.UsersModel.getStudentPaymentSetupIntent(this.student.id, 'setup').then(function (response) {
            _this.intent = response.intent;
        });
    };
    StudentPaymentMethodsController.prototype.tokenizationSupported = function () {
        if (this.gateway === "authorize" && this.gateway_config.authorize_api_client_key) {
            return true;
        }
        if (this.gateway === "stripe") {
            return true;
        }
        return false;
    };
    StudentPaymentMethodsController.prototype.removePaymentMethod = function (paymentMethodID) {
        var _this = this;
        this.UsersModel.removeStudentPaymentMethod(this.student.id, paymentMethodID).then(function (response) {
            _this.FlashService.setMessage({
                'type': 'success',
                'message': response.message
            });
            _this.UsersModel.getStudentPaymentMethods(_this.student.id).then(function (response) {
                _this.paymentMethods = response.payment_methods;
                _this.loading = false;
            });
        }).catch(function (response) {
            _this.FlashService.setMessage({
                'type': 'error',
                'message': response.message
            });
            _this.paymentMethods = _this.paymentMethods.filter(function (paymentMethod) {
                return paymentMethod.id !== paymentMethodID;
            });
        });
    };
    StudentPaymentMethodsController.prototype.handleAuthorizeCard = function (opaqueData) {
        var _this = this;
        this.UsersModel.addStudentPaymentMethod(this.student.id, {
            opaqueDataValue: opaqueData.dataValue,
            opaqueDataDescriptor: opaqueData.dataDescriptor,
            update_card: false,
            address: {
                street: this.billing_address.street,
                city: this.billing_address.city,
                state: this.billing_address.state,
                zip: this.billing_address.zip
            }
        }).then(function (response) {
            _this.FlashService.setMessage({
                'type': 'success',
                'message': response.message
            });
        });
    };
    return StudentPaymentMethodsController;
}());
StudentPaymentMethodsController.$inject = ['$stateParams', 'UsersModel', 'PoliciesModel', 'PolicyService', 'FlashService', 'UserService', '$location'];
exports.default = StudentPaymentMethodsController;
