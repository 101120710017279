"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreditCard = void 0;
var Address_1 = require("./Address");
var CreditCard = /** @class */ (function () {
    function CreditCard(data) {
        if (data === void 0) { data = {}; }
        // @ts-ignore
        this.$originalData = Object.assign({}, data);
        // @ts-ignore
        var name_on_card = data.name_on_card, number = data.number, expiration_year = data.expiration_year, expiration_month = data.expiration_month, cvc = data.cvc;
        this.$name_on_card = name_on_card || '';
        this.$number = number;
        this.$expiration_year = expiration_year;
        this.$expiration_month = expiration_month;
        this.$expiration_date = new Date();
        this.$expiration_date.setMonth(expiration_month - 1);
        this.$expiration_date.setFullYear(expiration_year);
        this.$cvc = cvc;
        this.$token = null;
        this.$billing_address = new Address_1.Address();
        this.$save_payment_method = false;
    }
    CreditCard.prototype.token = function () {
        return this.$token;
    };
    /**
     * Set the token data
     */
    CreditCard.prototype.tokenize = function (tokenData) {
        this.$token = tokenData;
        this.$number = '';
        this.$expiration_year = '';
        this.$expiration_month = '';
        this.$cvc = '';
    };
    CreditCard.prototype.number = function () {
        return this.$number;
    };
    CreditCard.prototype.cvv = function () {
        return this.$cvc;
    };
    /**
     * @return {Date} The JavaScript Date representation of this cards expiration month & year
     */
    CreditCard.prototype.expirationDate = function () {
        return this.$expiration_date;
    };
    CreditCard.prototype.expirationYear = function () {
        return this.$expiration_year;
    };
    CreditCard.prototype.expirationMonth = function () {
        return this.$expiration_month;
    };
    CreditCard.prototype.setName = function (name) {
        this.$name_on_card = name;
    };
    CreditCard.prototype.nameOnCard = function () {
        return this.$name_on_card;
    };
    CreditCard.prototype.billingAddress = function () {
        return this.$billing_address;
    };
    /**
     * Set the billing address for this card
     * @param {Address} address The address to make the billing address for this card.
     */
    CreditCard.prototype.setBillingAddress = function (address) {
        this.$billing_address = address;
    };
    return CreditCard;
}());
exports.CreditCard = CreditCard;
