<template>
  <section class="container-fluid">
    <div class="row">
      <div class="col-xs-12">
        <form @submit.prevent="submit()" novalidate>
          <fieldset>
            <section style="margin-bottom: 2em;" v-if="includeBillingAddress">
              <h3>Billing Address</h3>
              <fieldset>
                <div class="form-group row">
                  <div class="col-sm-12">
                    <label for="billing_address_street">Street</label>
                    <input
                      class="form-control"
                      v-model="billingAddress.street"
                      id="billing_address_street"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-sm-12 col-md-4">
                    <label for="billing_address_city">City</label>
                    <input
                      class="form-control"
                      v-model="billingAddress.city"
                      id="billing_address_city"
                    />
                  </div>
                  <div class="col-sm-12 col-md-4">
                    <label for="billing_address_state">State</label>
                    <select
                      class="form-control"
                      v-model="billingAddress.state"
                      id="billing_address_state"
                    >
                      <option
                        v-for="(opt, $index) in states"
                        :key="$index"
                        :value="opt.value"
                      >
                        {{ opt.name }}
                      </option>
                  </select>

                  </div>
                  <div class="col-sm-12 col-md-4">
                    <label for="billing_address_zip">Zip/Postal Code</label>
                    <input
                      class="form-control"
                      v-model="billingAddress.zip"
                      id="billing_address_zip"
                    />
                  </div>
                </div>
              </fieldset>
            </section>
            <h3>Card Information</h3>
            <div class="form-group">
              <label for="card_number">Card Number</label>
              <input
                class="form-control"
                v-model="payment.number"
                id="card_number"
              />
            </div>
            <div class="form-group row">
              <section>
                <div class="col-xs-12 col-md-4">
                  <label for="expiration_month">Expiration Month</label>
                  <select
                    class="form-control"
                    id="expiration_month"
                    v-model="payment.expiration_month"
                    required
                  >
                    <option
                      v-for="(opt, $index) in months"
                      :key="$index"
                      :value="opt.value"
                    >
                      {{ opt.name }}
                    </option>
                  </select>
                </div>
                <div class="col-xs-12 col-md-4">
                  <label for="expiration_year">Expiration Year</label>
                  <select
                    class="form-control"
                    v-model="payment.expiration_year"
                    id="expiration_year"
                    required
                  >
                    <option
                      v-for="(opt, $index) in years"
                      :key="$index"
                      :value="opt.value"
                    >
                      {{ opt.name }}
                    </option>
                  </select>
                </div>
                <div class="col-xs-12 col-md-4">
                  <label for="cvc">CVC</label>
                  <input
                    class="form-control"
                    required
                    v-model="payment.cvc"
                    id="cvc"
                  />
                </div>
              </section>
            </div>
          </fieldset>

          <div class="row" style="margin-bottom: 2em;" v-if="tokenizationSupported === true">
              <div class="col-sm-12">
                  <fieldset>
                      <label class="checkbox" for="save_payment_method">
                          <input type="checkbox"
                              v-model="payment.save_payment_method"
                              id="save_payment_method">
                          <span class="outer">
                              <span class="inner"></span>
                          </span>
                          Save this payment method
                      </label>
                  </fieldset>
              </div>
          </div>

          <div class="text-center">
            <button
              class="btn btn-dc"
              :disabled="buttonDisabled"
              style="margin-top: 2em"
            >
              {{ buttonText }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    apiClientKey: { type: String, default: "" },
    apiLoginId: { type: String, default: "" },
    billingAddress : { type:Object, default: function(){
      return {}
    }},
    includeBillingAddress: {
      type: Boolean, default: false
    },
    tokenizationSupported: {type : Boolean, default: false },
    buttonDisabled: { type: Boolean, default: false },
    buttonText: { type: String, default: "Submit" },
    months: { type: Array, default: [{ name: "", value: "" }] },
    years: { type: Array, default: [{ name: "", value: "" }] },
    states: { type: Array, default: [{ name: "", value: "" }] },
  },
  data() {
    return {
      payment: {
        number: "",
        expiration_month: "",
        expiration_year: "",
        cvc: "",
        save_payment_method: false
      },
    };
  },
  mounted() {},
  methods: {
    submit() {
      var authData = {};
      authData.clientKey = this.apiClientKey;
      authData.apiLoginID = this.apiLoginId;
      var cardData = {};
      cardData.cardNumber = this.payment.number;
      cardData.month = this.payment.expiration_month;
      cardData.year = this.payment.expiration_year;
      cardData.cardCode = this.payment.cvc;

      // If using banking information instead of card information,
      // build a bankData object instead of a cardData object.
      //
      // var bankData = {};
      //     bankData.accountNumber = document.getElementById('accountNumber').value;
      //     bankData.routingNumber = document.getElementById('routingNumber').value;
      //     bankData.nameOnAccount = document.getElementById('nameOnAccount').value;
      //     bankData.accountType = document.getElementById('accountType').value;

      var secureData = {};
      secureData.authData = authData;
      secureData.cardData = cardData;
      // If using banking information instead of card information,
      // send the bankData object instead of the cardData object.
      //
      // secureData.bankData = bankData;

      if (!this.apiClientKey) {
        let d = Object.assign({}, this.payment);
        if (this.billingAddress) {
          d.billing_address = Object.assign({}, this.billingAddress);
        }
        this.$emit("cardTokenCreated", Object.assign({}, d));
        this.payment.number = "";
        this.payment.expiration_month = "";
        this.payment.expiration_year = "";
        this.payment.cvc = "";
        this.payment.save_payment_method = false;
      } else {
        Accept.dispatchData(secureData, this.responseHandler);
      }
    },
    responseHandler(response) {
      if (response.messages.resultCode === "Error") {
        console.log(response.messages);
        var i = 0;
        while (i < response.messages.message.length) {
          console.log(
            response.messages.message[i].code +
              ": " +
              response.messages.message[i].text
          );
          i = i + 1;
        }
      } else {
        this.paymentFormUpdate();
        this.tokenCreated(
          response.opaqueData.dataDescriptor,
          response.opaqueData.dataValue
        );
      }
    },
    paymentFormUpdate(opaqueData) {
      // document.getElementById("dataDescriptor").value = opaqueData.dataDescriptor;
      // document.getElementById("dataValue").value = opaqueData.dataValue;

      // reset fields
      this.payment.number = "";
      this.payment.expiration_month = "";
      this.payment.expiration_year = "";
      this.payment.cvc = "";

      //   document.getElementById("accountNumber").value = "";
      //   document.getElementById("routingNumber").value = "";
      //   document.getElementById("nameOnAccount").value = "";
      //   document.getElementById("accountType").value = "";

      // send to server
      //   document.getElementById("paymentForm").submit();
    },
    tokenCreated(dataDescriptor, dataValue) {
      this.$emit("cardTokenCreated", {
        dataDescriptor: dataDescriptor,
        dataValue: dataValue,
      });
    },
    pay() {
      this.$refs.paymentRef.submit();
    },
  },
};
</script>
  
