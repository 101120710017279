import isNil from 'lodash/isNil'
import moment from 'moment'

function AdminTimeOffRequestsViewController( $state, $stateParams, TimeoffRequestsModel, FlashService ) {

    const vm = this;
    vm.request = {};
    vm.approveRequest = approveRequest;
    vm.rejectRequest = rejectRequest;
    vm.instructorName = '';
    vm.busy = {
        approving: false,
        rejecting: false
    };

    init();

    function init(){
        TimeoffRequestsModel.getRequestByID( $stateParams.timeOffRequestId ).then( function ( response ) {
            vm.request = {
                'id' : response.id,
                'user_id' : response.user_id,
                'begin' : moment( response.begin ).format( 'MM/DD/YYYY hh:mm a' ),
                'end' : moment( response.end ).format( 'MM/DD/YYYY hh:mm a' ),
                'status' : response.status,
                'reason' : response.reason,
                'notes' : response.notes
            }
            vm.instructorName = response.instructor.fullname
        } );
    }

    function approveRequest() {
        vm.busy.approving = true;
        FlashService.clearFlash();

        const approvedRequest = {
            'id' : vm.request.id,
            'user_id' : vm.request.user_id,
            'status' : 'Approved',
            'reason' : vm.request.reason
        };
        TimeoffRequestsModel.update( approvedRequest ).then( function ( response ) {
            vm.busy.approving = false;
            FlashService.setMessage( {
                'type' : 'success',
                'message' : response.message
            } );
            let request = response.request
            vm.request = {
                'id' : request.id,
                'user_id' : request.user_id,
                'begin' : moment( request.begin ).format( 'MM/DD/YYYY hh:mm a' ),
                'end' : moment( request.end ).format( 'MM/DD/YYYY hh:mm a' ),
                'status' : request.status,
                'reason' : request.reason,
                'notes' : request.notes
            }
            $state.go( 'drivecoach.admin.instructors.timeoffrequests', {}, { 'reload' : true } );

        })
        .catch( error => vm.busy.approving = false );
    }

    function rejectRequest() {
        vm.busy.rejecting = true;
        FlashService.clearFlash();

        if( isNil(vm.request.notes) ){
            vm.busy.rejecting = false;
            return FlashService.setMessage({
                'type' : 'danger',
                'message' : 'Notes are required if rejecting a request for time off.'
            });
        }

        const rejectedRequest = {
            'id' : vm.request.id,
            'user_id' : vm.request.user_id,
            'status' : 'Rejected',
            'reason' : vm.request.reason,
            'notes' : vm.request.notes
        };

        TimeoffRequestsModel.update( rejectedRequest ).then( function ( response ) {
            vm.busy.rejecting = false;
            FlashService.setMessage( {
                'type' : 'success',
                'message' : response.message
            });
            let request = response.request
            vm.request = {
                'id' : request.id,
                'user_id' : request.user_id,
                'begin' : moment( request.begin ).format( 'MM/DD/YYYY hh:mm a' ),
                'end' : moment( request.end ).format( 'MM/DD/YYYY hh:mm a' ),
                'status' : request.status,
                'reason' : request.reason,
                'notes' : request.notes
            }

            $state.go( 'drivecoach.admin.instructors.timeoffrequests', {}, { 'reload' : true } );
        })
        .catch( error => vm.busy.rejecting = false );
    }
}
AdminTimeOffRequestsViewController.$inject = [ '$state', '$stateParams', 'TimeoffRequestsModel', 'FlashService'];
export default AdminTimeOffRequestsViewController;