import {saveToDisk} from "../../../../../common/helpers/saveToDisk";
import { dateTimeStringToDate } from "../../../../../common/helpers/time";

class AdminStudentReportCardsController
{

    constructor($stateParams, UsersModel, reportcards, student){
        this.$stateParams = $stateParams;
        this.reportcards = reportcards;
        this.printing = false;
        this.totalItems = reportcards.total;
        this.currentPage = reportcards.current_page;
        this.reportcards = reportcards.data.map(reportcard => {
            reportcard.start = dateTimeStringToDate(reportcard.drive.drive_date + ' ' + reportcard.drive.start_time).toLocaleDateString('en-US');
            return reportcard;
        });
        this.page_by = reportcards.per_page;

        this.pageChanged = this.pageChanged.bind(this);
        this.loading = false;
        this.student = student;

        this.UsersModel = UsersModel;
        this.student = student;
        this.pageChanged = this.pageChanged.bind(this);
        this.print = this.print.bind(this);
    }
    

    pageChanged() {
        this.UsersModel.getStudentReportcards({
            'id': this.student.id,
            'page': this.currentPage,
            'page_by' : 20,
        }).then(response => {
            this.reportcards = response.reportcards.data.map(reportcard => {
                reportcard.start = dateTimeStringToDate(reportcard.drive.drive_date + ' ' + reportcard.drive.start_time).toLocaleDateString('en-US');
                return reportcard;
            });
            this.totalItems = response.reportcards.total;
            this.page_by = response.reportcards.per_page;
            this.currentPage = response.reportcards.current_page;
        });
    }

    print() {
        this.printing = true;
        this.UsersModel.printStudentReportcards({'id': this.$stateParams.studentId}).then(response => {
            this.printing = false;
            saveToDisk(response.file.url, response.file.name);
        });
    }

}
AdminStudentReportCardsController.$inject = ['$stateParams', 'UsersModel', 'reportcards', 'student'];
export default AdminStudentReportCardsController;