import { States } from '../../../common/constants/states'
import { PaymentMethods } from "../../../common/constants/payment_methods";
import { Months, Years } from "../../../common/constants/months_and_years";
import { createBillingGatewayFromPolicies } from '../../../classes/billing/BillingGateway';
import { inDollars } from '../../../../DriveScout/Billing/Teller';
import { Product } from '../../../classes/Product';
import { Coupon } from '../../../classes/Coupon';
import { Student } from '../../../classes/Student';
import { Address } from '../../../classes/Address';
import { CreditCard } from '../../../classes/CreditCard';
class dsOrderFormController {

    constructor($scope, $location, UsersModel, FlashService, CreateOrderService, SchoolsModel) {
        this.loading = true;
        this.submitting = false;
        this.inDollars = inDollars.bind(this);
        this.coupons = [];
        this.CreateOrderService = CreateOrderService;
        this.SchoolsModel = SchoolsModel;
        this.UsersModel = UsersModel;
        this.FlashService = FlashService;
        this.billingAddressDoesNotAlreadyExist = false;
        this.gateway = 'none';
        this.gateway_config = {};
        this.returnUrl = $location.absUrl();

        this.surchargeAmountBilled = 0;

        this.handleAuthorizeCard = this.handleAuthorizeCard.bind(this);
        this.handleCompletedConfirmationToken = this.handleCompletedConfirmationToken.bind(this);
        this.setStudentCart = this.setStudentCart.bind(this);
        this.tokenizationSupported = this.tokenizationSupported.bind(this);
        this.showUseExistingPaymentMethodUI = false;

        this.billingAddress = {
            street: "",
            city: "",
            state: "",
            zip: "",
            lat: "",
            lng: ""
        }

        /**
         * The coupon the admin selects to add to the order.
         */
        this.selectedCoupon = {};

        //////////////////////////////////

        /** @type Cashier */
        this.$cashier = this.CreateOrderService.cashier();

        $scope.$watch(() => this.$cashier.$order.$student, newVal => {
            if (!this.$cashier.$order.$student) return;
            if (this.gateway === 'stripe') {
                this.returnUrl = $location.absUrl() + '?student_id=' + this.$cashier.order().student().id();
            }
        });

        const policies = this.$cashier.policies();
        const billingPolicy = policies.getPolicyByName('billing');
        if (billingPolicy.metaExistsByKey('billing_gateway')) {
            this.gateway = billingPolicy.getMetaValueByKey('billing_gateway');
            this.gateway_config = createBillingGatewayFromPolicies(policies).config();
        }

        if (this.gateway === "stripe") {
            this.intent = {}
            this.setupIntentClientSecret = "";
            let student_id = 0;
            this.stripeElementOptions = {
                currency: 'usd',
                amount: undefined,
            }
            
            // try {
            
            //     const queryString = new URLSearchParams(window.location.search);
            //     if (queryString.has("payment_intent_client_secret")) {
            //         this.setupIntentClientSecret = queryString.get("payment_intent_client_secret");
            //     }
            //     if (queryString.has("student_id")) {
            //         student_id = queryString.get("student_id");
            //     }
            // } catch (Error) {
            //     this.setupIntentClientSecret = "";
            //     student_id = 0;
            // }

            // if (this.setupIntentClientSecret !== "" && student_id != 0) {
            //     this.handleCompletedPaymentIntent(student_id).then(response => {
            //         $scope.$apply(() => {
            //             this.setupIntentMessage = response.message,
            //             this.setupIntentStatus = 'success';
            //         });
            //     });
            // }
        }

        // set surcharge data
        this.surchargeBilledSeparately = this.$cashier.surchargeBilledSeparately();
        this.surchargeAmount = this.$cashier.surchargeAmount();
        this.surchargeDetail = this.$cashier.surchargeDetail();

        // set payment methods
        this.payment_methods = [].concat(PaymentMethods);
        if (this.gateway === "none") {
            this.payment_methods = this.payment_methods.filter(method => {
                return method.value !== 'card';
            });
            this.$cashier.order().payments()[0].$payment_method = 'other';
        }
        this.months = Months;
        this.years = Years;
        this.states = States;

        this.loading = false;
    }

    tokenizationSupported()
    {
        if (this.gateway === "authorize" && this.gateway_config.authorize_api_client_key) {
            return true;
        }

        if (this.gateway === "stripe") {
            return true;
        }

        return false;
    }

    buttonText()
    {
        let msg = "Submit Order";
        if (this.$cashier.order().payments()[0].amount()) {
            msg += " and Payment for " + this.$cashier.amountPaidToday();
        }
        return msg;
    }

    toggleUseExistingPaymentMethod()
    {
        this.showUseExistingPaymentMethodUI = !this.showUseExistingPaymentMethodUI;

        if (this.showUseExistingPaymentMethodUI === true) {
            delete this.$cashier.$order.$payments[0].$card.$payment_method_id;
            delete this.stripeElementOptions.amount;
        }
    }

    proceedToPayment(amount){
        delete this.$cashier.$order.$payments[0].$card.$payment_method_id;
        this.showUseExistingPaymentMethodUI = false;
        this.$cashier.$order.$payments[0].$amount = amount;
        this.stripeElementOptions.amount = amount;
    }

    setStudentCart()
    {
        let payload = {
            products: [].concat(this.$cashier.order().products().map(product => {
                return { id: product.id() }
            })),
            payment_amount: this.$cashier.$order.$payments[0].$amount,
            payment_method: this.$cashier.$order.$payments[0].$payment_method,
        }
        if (this.$cashier.order().coupon() != null) {
            payload.coupon = {
                id: this.$cashier.order().coupon().id()
            }
        }
        if (this.$cashier.order().payments()[0].creditCard().billingAddress().street()) {
            payload.billing_address = {
                street: this.$cashier.order().payments()[0].creditCard().billingAddress().street(),
                city: this.$cashier.order().payments()[0].creditCard().billingAddress().city(),
                state: this.$cashier.order().payments()[0].creditCard().billingAddress().region(),
                zip: this.$cashier.order().payments()[0].creditCard().billingAddress().postalCode(),
            }
        }

        return this.UsersModel.setStudentCart(this.$cashier.order().student().id(), payload).then(response => {
            return response;
        }).catch(Error => {
            console.error(Error)
        })
    }

    async handleCompletedConfirmationToken(token)
    {
        let { cart } = await this.UsersModel.getStudentCart(this.$cashier.order().student().id());
        let {products, coupon, student, billing_address, payment_amount, payment_method} = cart;
        const $products = products.map(p => new Product(p));
        const $student = new Student(student);
        this.$cashier.order().setProducts($products);
        this.$cashier.order().setStudent($student);
        if (coupon) {
            const $coupon = new Coupon(coupon);
            this.$cashier.order().setCoupon($coupon);
        }

        if (billing_address) {
            const address = new Address(0, "", billing_address.street, billing_address.city, billing_address.state, billing_address.zip, 0, 0);
            this.$cashier.$order.$payments[0].$card.$billing_address = address;
        }

        this.$cashier.$order.$payments[0].$amount = payment_amount;
        this.$cashier.$order.$payments[0].$payment_method = payment_method;

        // set token data on order
        this.$cashier.order().$payments = this.$cashier.order().payments().map(payment => {
            if (payment.paymentMethod() === 'card') {
                payment.creditCard().tokenize(token);
                payment.$card.$save_payment_method = this.$cashier.order().payments()[0].$card.$save_payment_method;
            }
            return payment;
        });

        this.submit(this.$cashier.order());
        return true;
    }

    handleAuthorizeCard(tokenData)
    {
        let valid = this.$cashier.order().products().length === 0 || !this.$cashier.order().student() || this.$cashier.order().submitted === true;
        if (!valid) {
            // fail
        }

        if (!this.gateway_config.authorize_api_client_key) {
            const card = new CreditCard(tokenData);
            if (tokenData.billing_address) {
                const address = new Address(
                    0,
                    "",
                    tokenData.billing_address.street,
                    tokenData.billing_address.city,
                    tokenData.billing_address.state,
                    tokenData.billing_address.zip,
                    0,
                    0
                );
                card.setBillingAddress(address);
            }
            card.$save_payment_method = tokenData.save_payment_method;
            this.$cashier.order().payments()[0].$card = card;
        } else {
            // set token data on order
            this.$cashier.order().$payments = this.$cashier.order().payments().map(payment => {
                if (payment.paymentMethod() === 'card') {
                    payment.creditCard().tokenize({opaqueData: tokenData});
                    payment.$card.$save_payment_method = this.$cashier.order().payments()[0].$card.$save_payment_method;
                }
                return payment;
            });
        }

        this.submit(this.$cashier.order());
    }

    removeCoupon(){
        this.$cashier.removeCoupon();
        this.$cashier.order().payments()[0].setAmount(this.$cashier.totalDue());
        this.$cashier.calculateTotal();
    }

    removeProductFromOrder(index, product) {
        // @todo bug fix, send index into remove product
        this.$cashier.removeProduct(product);

        let oldPaymentAmount = this.$cashier.order().payments()[0].amount()
        let newTotalWithoutSurcharge = this.$cashier.totalDue()
        if(oldPaymentAmount > newTotalWithoutSurcharge) {
            this.$cashier.order().payments()[0].setAmount(newTotalWithoutSurcharge);
        }

        this.$cashier.calculateTotal();
    }

    /**
     * Set the billing address of the given payment at the index provided.
     * @param {number} index
     * @param {Address} address
     */
    addBillingAddress(index, address) {
        this.billingAddress = {
            street: address.street(),
            state: address.region(),
            city: address.city(),
            zip: address.postalCode()
        };
        this.$cashier.addBillingAddress(index, address);
        this.$cashier.calculateTotal();
    }

    selectPaymentMethod(id, paymentIndex) {
        this.$cashier.setCustomerPaymentMethod(id, paymentIndex);
    }
}
dsOrderFormController.$inject = ['$scope', '$location', 'UsersModel', 'FlashService', 'CreateOrderService', 'SchoolsModel'];
export default dsOrderFormController;
