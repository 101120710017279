import angular from "angular";
import StudentController from "./student.controller";
import StudentNav from "./directives/student-nav/student-nav.module";
import StudentOrderForm from "./directives/order-form/order-form.module";
import FooterNav from "./directives/student-footer-nav/student-footer-nav.module";
import CompleteSetup from "./directives/complete-setup/complete-setup.module";
import Dashboard from "./dashboard/student-dashboard.module";
import Reportcard from "./report-card/student-report-card.module";
import Account from "./account/student-account.module";
import Drives from "./drives/student-drives.module";
import ProgressReport from "./student-progress-report/student-progress-report.module";
import Settings from "./settings/student-settings.module";
import Fees from "./fees/student-fees.module";
import Orders from "./orders/student-orders.module";
import PurchaseNewServices from "./purchase-new-services/purchase-new-services.module";
import StudentOrder from "./order/order.module";
import AvailableCourses from "./available-courses/available-courses.module";
import BookClass from "./book-class/book-class.module";
import Classes from "./classes/classes.module";
import StudentCourseHistory from './past-classes-and-courses/past-classes-and-courses'
import OnlineDriversEdSuccess from './online-drivers-ed-success/online-drivers-ed-success.module'
import OnlineDriversEd from './online-drivers-ed/online-drivers-ed.module'
import PaymentMethods from './payment-methods/payment-methods.module'

import { createFromPolicyAPIResponse } from "../classes/Policies";
import { School } from "../classes/School";
import { Cashier } from "../classes/Cashier";
import { Student } from "../classes/Student";

import template from "./student.html";
import { Order } from "../classes/Order";

async function getCurrentUser(
  AuthService,
  UserService,
  CreateOrderService,
  PoliciesModel,
  PolicyService
) {
  const response = await AuthService.getCurrentUser();

  UserService.setCurrentUser(response);

  const user = UserService.getCurrentUser();
  const student = new Student(user);

  const policyresponse = await PoliciesModel.get();

  const policies = createFromPolicyAPIResponse(policyresponse);
  const school = new School({
    ...policyresponse.school,
    policies: policies.toArray(),
  });
  policies.setSchool(school);
  school.setPolicies(policies);
  const cashier = new Cashier(school, new Order());
  cashier.addStudent(student);
  CreateOrderService.cashier(cashier);
  PolicyService.setPolicies(policies);

  return UserService.getCurrentUser();
}
getCurrentUser.$inject = [
  "AuthService",
  "UserService",
  "CreateOrderService",
  "PoliciesModel",
  "PolicyService",
];

function config($stateProvider, $urlRouterProvider) {
  $stateProvider.state("drivecoach.student", {
    url: "/student",
    controller: StudentController,
    resolve: {
      currentUser: getCurrentUser,
    },
    controllerAs: "vm",
    template: template,
  });
}
config.$inject = ["$stateProvider", "$urlRouterProvider"];

export default angular
  .module("student", [
    StudentNav.name,
    FooterNav.name,
    CompleteSetup.name,
    Dashboard.name,
    Reportcard.name,
    Account.name,
    Drives.name,
    ProgressReport.name,
    Settings.name,
    Fees.name,
    Orders.name,
    PurchaseNewServices.name,
    StudentOrderForm.name,
    StudentOrder.name,
    AvailableCourses.name,
    BookClass.name,
    Classes.name,
    StudentCourseHistory.name,
    OnlineDriversEdSuccess.name,
    OnlineDriversEd.name,
    PaymentMethods.name,
  ])
  .config(config)
  .controller("StudentController", StudentController);
