"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var CancelDriveController = /** @class */ (function () {
    function CancelDriveController($state, FlashService, DrivesModel, UserService, drive, rules) {
        this.$state = $state;
        this.FlashService = FlashService;
        this.DrivesModel = DrivesModel;
        this.UserService = UserService;
        this.drive = drive;
        this.canceling = false;
        this.timeWarning = rules.timeWarning;
        this.fee = rules.fee;
        this.cancellationTime = rules.cancellationTime;
        this.showFeeWarning = rules.showFeeWarning;
        this.cancelDriveSession = this.cancelDriveSession.bind(this);
        this.parseInt = this.parseInt.bind(this);
    }
    CancelDriveController.prototype.parseInt = function (number) {
        return parseInt(number, 10);
    };
    CancelDriveController.prototype.cancelDriveSession = function (drive) {
        var _this = this;
        delete drive.cancelation_reason;
        delete drive.cancelation_reason_explanation;
        this.canceling = true;
        var student_id = this.UserService.getCurrentUser().id;
        var data = Object.assign({}, drive);
        data.student_id = student_id;
        this.DrivesModel.cancelDriveSession(data).then(function (response) {
            _this.FlashService.setMessage({
                type: "success",
                message: response.message,
            });
            _this.canceling = false;
            _this.$state.go("drivecoach.student.dashboard", {}, { reload: true });
        }).catch(function (Error) {
            _this.canceling = false;
        });
    };
    return CancelDriveController;
}());
CancelDriveController.$inject = ['$state', 'FlashService', 'DrivesModel', 'UserService', 'drive', 'rules'];
exports.default = CancelDriveController;
