"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var payment_methods_1 = require("../../../common/constants/payment_methods");
var months_and_years_1 = require("../../../common/constants/months_and_years");
var months_and_years_2 = require("../../../common/constants/months_and_years");
var states_1 = require("../../../common/constants/states");
var Teller_1 = require("../../../../DriveScout/Billing/Teller");
var StudentFeePayController = /** @class */ (function () {
    function StudentFeePayController($scope, $state, $stateParams, $q, UsersModel, FlashService, PoliciesModel, $location, fee, FeeModel, UserService) {
        var _this = this;
        this.loading = true;
        this.$scope = $scope;
        this.$state = $state;
        this.$location = $location;
        this.$q = $q;
        this.$stateParams = $stateParams;
        this.UsersModel = UsersModel;
        this.UserService = UserService;
        this.FlashService = FlashService;
        this.FeeModel = FeeModel;
        this.payment = {
            card: {},
            payment_amount: undefined,
            payment_method: "card"
        };
        this.returnUrl = $location.absUrl();
        this.payments = [];
        this.fees = [];
        this.addresses = [];
        this.paymentMethods = [];
        this.fee = null;
        this.payment_methods = payment_methods_1.PaymentMethods.filter(function (method) {
            return method.value === "card";
        });
        this.months = months_and_years_1.Months;
        this.years = months_and_years_2.Years;
        this.states = states_1.States;
        this.student = UserService.getCurrentUser();
        this.FeeModel.getFee($stateParams.feeId).then(function (response) {
            _this.fee = response.fee;
        });
        this.billingAddress = {
            street: "",
            city: "",
            state: "",
            zip: ""
        };
        this.policies = {
            'billing': {
                'policymeta': {}
            }
        };
        this.surchargeBilledSeparately = false;
        this.surchargeDetail = '';
        this.surchargeAmountBilled = 0;
        this.surchargeAmount = 0;
        this.paidToday = 0;
        this.handleAuthorizeCard = this.handleAuthorizeCard.bind(this);
        this.handleCompletedConfirmationToken = this.handleCompletedConfirmationToken.bind(this);
        this.formatPaymentAmount = this.formatPaymentAmount.bind(this);
        this.setStudentCart = this.setStudentCart.bind(this);
        this.buttonText = this.buttonText.bind(this);
        this.proceedToPayment = this.proceedToPayment.bind(this);
        this.submitPayment = this.submitPayment.bind(this);
        this.tokenizationSupported = this.tokenizationSupported.bind(this);
        $q.all([
            PoliciesModel.getByID('billing'),
            UsersModel.getStudentByID(this.student.id),
            UsersModel.getPickupLocations(this.student.id),
            UsersModel.getStudentPaymentMethods(this.student.id)
        ]).then(function (responses) {
            var billingResponse = responses[0];
            var getStudentResponse = responses[1];
            var getAddressesResponse = responses[2];
            var getStudentPaymentMethodsResponse = responses[3];
            var policyMeta = {};
            billingResponse.policy.policymeta.forEach(function (meta) {
                policyMeta[meta.meta_key] = meta.meta_value;
            });
            _this.policies.billing.policymeta = policyMeta;
            _this.policies.billing.policymeta.id = billingResponse.policy.id;
            _this.gateway = _this.policies.billing.policymeta.billing_gateway;
            _this.gateway_config = {
                stripe_publishable_key: _this.policies.billing.policymeta.stripe_publishable_key,
                authorize_api_client_key: _this.policies.billing.policymeta.authorize_client_key,
                authorize_trans_key: _this.policies.billing.policymeta.authorize_api_trans_key,
                authorize_api_login_id: _this.policies.billing.policymeta.authorize_api_login_id,
                paypal_rest_api_client_id: _this.policies.billing.policymeta.paypal_rest_api_client_id,
            };
            if (policyMeta.hasOwnProperty('surcharge_amount')) {
                _this.surchargeBilledSeparately = parseFloat(policyMeta.surcharge_amount) > 0;
                _this.surchargeAmount = parseFloat(policyMeta.surcharge_amount);
            }
            if (policyMeta.hasOwnProperty('surcharge_detail')) {
                _this.surchargeDetail = policyMeta.surcharge_detail;
            }
            _this.student = getStudentResponse;
            if (_this.student.school.allows_electronic_payment === false) {
                // @todo display error on ui
            }
            ///
            _this.addresses = getAddressesResponse.pickuplocations.map(function (location) {
                return location.address;
            });
            ///
            _this.paymentMethods = getStudentPaymentMethodsResponse.payment_methods;
            if (_this.gateway === "stripe") {
                _this.stripeElementOptions = {
                    currency: 'usd',
                    amount: undefined,
                };
            }
            _this.loading = false;
        });
    }
    StudentFeePayController.prototype.buttonText = function () {
        var msg = "Submit Payment";
        if (this.payment.payment_amount && this.checkSurchargeRequired()) {
            msg += " for " + (0, Teller_1.inDollars)(this.calculateTotal());
        }
        return msg;
    };
    StudentFeePayController.prototype.toggleUseExistingPaymentMethod = function () {
        this.showUseExistingPaymentMethodUI = !this.showUseExistingPaymentMethodUI;
        if (this.showUseExistingPaymentMethodUI === true) {
            delete this.payment.card.payment_method_id;
            delete this.stripeElementOptions.amount;
        }
    };
    StudentFeePayController.prototype.proceedToPayment = function (amount) {
        delete this.payment.card.payment_method_id;
        this.showUseExistingPaymentMethodUI = false;
        this.stripeElementOptions.amount = amount;
    };
    StudentFeePayController.prototype.formatPaymentAmount = function (amount) {
        return (0, Teller_1.inDollars)(amount);
    };
    StudentFeePayController.prototype.selectPaymentMethod = function (paymentMethodID) {
        this.payment.card = {
            payment_method_id: paymentMethodID,
            use_existing_payment_method: true
        };
    };
    StudentFeePayController.prototype.setStudentCart = function () {
        var payload = {
            fee: this.fee,
            payment_amount: this.payment.payment_amount,
            payment_method: this.payment.payment_method,
        };
        if (this.payment.card.billing_address) {
            //@ts-ignore
            payload.billing_address = {
                street: this.payment.card.billing_address.street,
                city: this.payment.card.billing_address.city,
                state: this.payment.card.billing_address.state,
                zip: this.payment.card.billing_address.zip,
            };
        }
        return this.UsersModel.setStudentCart(this.student.id, payload).then(function (response) {
            return response;
        }).catch(function (Error) {
            console.error(Error);
        });
    };
    StudentFeePayController.prototype.tokenizationSupported = function () {
        if (this.gateway === "authorize" && this.gateway_config.authorize_api_client_key) {
            return true;
        }
        if (this.gateway === "stripe") {
            return true;
        }
        return false;
    };
    StudentFeePayController.prototype.handleAuthorizeCard = function (tokenData) {
        if (!this.gateway_config.authorize_api_client_key) {
            this.payment.card = tokenData;
        }
        else {
            this.payment.card.token = {
                opaqueData: tokenData
            };
            delete this.payment.card.name_on_card;
            delete this.payment.card.number;
            delete this.payment.card.expiration_month;
            delete this.payment.card.expiration_year;
            delete this.payment.card.cvc;
        }
        this.submitPayment();
    };
    StudentFeePayController.prototype.handleCompletedConfirmationToken = function (token) {
        return __awaiter(this, void 0, void 0, function () {
            var cart, fee, billing_address, payment_amount, payment_method;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.UsersModel.getStudentCart(this.student.id)];
                    case 1:
                        cart = (_a.sent()).cart;
                        fee = cart.fee, billing_address = cart.billing_address, payment_amount = cart.payment_amount, payment_method = cart.payment_method;
                        this.fee = fee;
                        if (billing_address) {
                            this.payment.card.billing_address = billing_address;
                        }
                        this.payment.payment_amount = payment_amount;
                        this.payment.payment_method = payment_method;
                        // set token data on order
                        if (this.payment.payment_method === 'card') {
                            this.payment.card.token = token;
                        }
                        this.submitPayment();
                        return [2 /*return*/, true];
                }
            });
        });
    };
    StudentFeePayController.prototype.checkSurchargeRequired = function () {
        return this.surchargeBilledSeparately && this.payment.payment_method === 'card';
    };
    StudentFeePayController.prototype.calculateTotal = function () {
        if (this.surchargeBilledSeparately && this.payment.payment_method === 'card') {
            var surchargeAsDecimal = this.surchargeAmount / 100;
            var surcharge = (this.payment.payment_amount * surchargeAsDecimal) / 100;
            this.surchargeAmountBilled = surcharge.toFixed(2);
            var orderTotal = (100 * (Math.round(surcharge * 100) / 100)) + this.fee.amount;
            var total = this.payment.payment_amount + ((Math.round(surcharge * 100 / 100)) * 100);
            var totalInDollars = total / 100;
            this.orderTotal = orderTotal;
            this.paidToday = totalInDollars.toFixed(2);
            return surcharge.toFixed(2);
        }
        var orderTotalInCents = this.fee.amount;
        this.fee.amount = orderTotalInCents;
        return orderTotalInCents;
    };
    StudentFeePayController.prototype.addBillingAddress = function (index, address) {
        if (!this.payment.hasOwnProperty("card")) {
            this.payment.card = {};
        }
        this.billingAddress = address;
    };
    StudentFeePayController.prototype.submitPayment = function () {
        var _this = this;
        this.submitted = true;
        var payment = Object.assign({}, this.payment);
        this.UsersModel.submitPayment(this.student.id, this.fee.id, {
            payment: payment
        })
            .then(function (response) {
            _this.submitted = false;
            _this.FlashService.setMessage({
                type: "success",
                message: response.message
            });
            _this.payment = {
                card: {},
                payment_amount: undefined,
                payment_method: "card"
            };
            _this.billingAddress = {
                street: "",
                city: "",
                state: "",
                zip: ""
            };
        })
            .catch(function (response) {
            _this.submitted = false;
        });
    };
    return StudentFeePayController;
}());
StudentFeePayController.$inject = [
    "$scope",
    "$state",
    "$stateParams",
    "$q",
    "UsersModel",
    "FlashService",
    "PoliciesModel",
    "$location",
    "fee",
    "FeeModel",
    "UserService"
];
exports.default = StudentFeePayController;
