"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var GradeOptionModel = /** @class */ (function () {
    function GradeOptionModel($http, APPURL) {
        this.$http = $http;
        this.URLS = {
            FETCH: APPURL + '/api/v1/grade-options',
            UPDATE: APPURL + '/api/v1/grade-options',
            CREATE: APPURL + '/api/v1/grade-options'
        };
        this.data = {};
        this.extract = this.extract.bind(this);
        this.cacheResults = this.cacheResults.bind(this);
    }
    GradeOptionModel.prototype.extract = function (result) {
        return result.data;
    };
    GradeOptionModel.prototype.cacheResults = function (result) {
        this.data = this.extract(result);
        return this.data;
    };
    GradeOptionModel.prototype.getGradeOptions = function () {
        return this.$http.get(this.URLS.FETCH).then(this.cacheResults);
    };
    ;
    GradeOptionModel.prototype.createGradeOption = function (gradeOption) {
        return this.$http.post(this.URLS.CREATE, { 'grade_option': gradeOption }).then(this.cacheResults);
    };
    ;
    GradeOptionModel.prototype.updateGradeOption = function (gradeOption) {
        return this.$http.patch(this.URLS.CREATE + '/' + gradeOption.id, gradeOption).then(this.cacheResults);
    };
    ;
    GradeOptionModel.prototype.removeGradeOption = function (id) {
        return this.$http.delete(this.URLS.CREATE + '/' + id).then(this.cacheResults);
    };
    ;
    return GradeOptionModel;
}());
GradeOptionModel.$inject = ['$http', 'APPURL'];
exports.default = GradeOptionModel;
