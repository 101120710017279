"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var FeeModel = /** @class */ (function () {
    function FeeModel($http, APPURL) {
        this.URLS = {
            FETCH: APPURL + '/api/v1/crm/fees',
            UPDATE: APPURL + '/api/v1/crm/fees',
            CREATE: APPURL + '/api/v1/crm/fees'
        };
        this.$http = $http;
        this.fees;
        this.extract = this.extract.bind(this);
        this.cacheResults = this.cacheResults.bind(this);
    }
    FeeModel.prototype.extract = function (result) {
        return result.data;
    };
    FeeModel.prototype.cacheResults = function (result) {
        this.fees = this.extract(result);
        return this.fees;
    };
    FeeModel.prototype.getFee = function (id) {
        return this.$http.get(this.URLS.FETCH + '/' + id).then(this.cacheResults);
    };
    ;
    FeeModel.prototype.destroy = function (id) {
        return this.$http.delete(this.URLS.FETCH + '/' + id).then(this.cacheResults);
    };
    return FeeModel;
}());
FeeModel.$inject = ['$http', 'APPURL'];
exports.default = FeeModel;
