import angular from 'angular'
import EditProductController from './edit-product.controller'
import template from './edit-product.html'
function getProduct( ProductsModel, $stateParams ) {
    return ProductsModel.show( $stateParams.product_id ).then( response => {
        response.product.price = response.product.price / 100;
        response.product.sessiontypes = response.product.sessiontypes.map(type => {
            type.hours = type.pivot.hours;
            type.observation = type.pivot.observation;
            return type;
        })
        response.product.coursetypes = response.product.coursetypes.map(type => {
            type.hours = type.pivot.hours;
            return type;
        })
        return response.product;
    } );
}
getProduct.$inject = ['ProductsModel', '$stateParams'];

function getLessonPlans( LessonPlanModel ) {
    return LessonPlanModel.get({'paging': 0}).then(response => {
        return response.lesson_plans;
    } );
}
getLessonPlans.$inject = ['LessonPlanModel'];

function getODEConfig(OnlineDriversEd) {
    return OnlineDriversEd.getFeatureDetails().then(response => {
        return response;
    });
}
getODEConfig.$inject = ['OnlineDriversEd'];

function config( $stateProvider, $urlRouterProvider, USER_ROLES ) {
    $stateProvider
        .state( 'drivecoach.admin.products.edit', {
            url : '/edit/:product_id',
            template : template,
            controller : EditProductController,
            controllerAs : 'vm',
            resolve : {
                product: getProduct,
                lessonPlans: getLessonPlans,
                OnlineDriversEdConfig: getODEConfig,
            },
            data : {
                authorizedRoles : [
                    USER_ROLES.admin,
                    USER_ROLES.administrator,
                    USER_ROLES.owner,
                    USER_ROLES.staff
                ]
            }
        } )
    ;
}
config.$inject = ['$stateProvider', '$urlRouterProvider', 'USER_ROLES'];

export default angular.module( 'admin.crm.products.edit', [] )
    .config(config)
    .controller('EditProductController', EditProductController);
