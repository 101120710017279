<template>
  <l-map 
    v-if="showElement" 
    :style="`height: ${height}; width: ${width}`"
    :center="center" 
    :options="leafletOptions"
    :bounds="bounds"
    :zoom=zoom
    @ready="handleReady(leafletMap)"
    ref="dcMap">
    
    <!-- geoJSON layer (zonepart/sector) center drivescout icon markers   -->
    <l-feature-group v-if="showCenterMarkers" ref="zoneCenterMarkersGroup">
      <l-marker 
        v-for="marker in zoneCenterMarkers" 
        :lat-lng="marker" 
        :icon="icon"
        >
        <l-tooltip :content="marker.popupData"></l-tooltip>
        </l-marker>
        </l-marker>
    </l-feature-group>
    
    <!-- markers without associated rendered zones passed in mapMarkers prop  -->
    <l-feature-group ref="markersGroup">
      <l-marker 
        v-for="marker in markers" 
        :lat-lng="marker" 
        :icon="icon"
        >
        <l-tooltip :content="marker.popupData"></l-tooltip>
        </l-marker>
    </l-feature-group>
    
    <!-- geojson layers/markers (zones)   -->
    <l-geo-json
      v-if="!minimal"
      v-for="layer in geoJSONLayers"
      :geojson="layer"
      :options="geoJSONOptions"
      :optionsStyle="layer.style">
    </l-geo-json>
    <!--  markers with geoJSON layer zone names ex. "A", "A1", etc  -->
    <l-marker
      v-if="!minimal"
      v-for="marker in geoJSONMarkers" 
      :lat-lng="marker.center" 
      :icon="marker.icon">
    </l-marker>
    
    <!--  zones legend  -->
    <l-control v-if="!minimal && showLegend" position="bottomright" >
      <div class="map-info map-legend">
        <span v-for="(zone, index) in zones">
          <i class="zone-theme" :style="`background-color: ${zone.theme.fill}`"></i> {{ zone.name }}
        </span>
      </div>
    </l-control>
    
  </l-map>
</template>

<script>
  import { latLngBounds, latLng } from 'leaflet';
  import { LMap, LMarker, LControl, LIcon, LGeoJson, LFeatureGroup, LPopup, LTooltip } from 'vue2-leaflet';
  import flattenDeep from 'lodash/flattenDeep'
  import { isJson } from "../../../helpers/json";
  import ColorHash from 'color-hash'
  const colorHash = new ColorHash();
  
  export default {
    name: 'dcMap',
    components: { LMap, LMarker, LControl, LIcon, LGeoJson, LFeatureGroup, LPopup, LTooltip },
    props: {
      //context used for specific maps like the admin view student's pickup locations map
      'context': { type: String },
      'height': { type: String, required: true },
      'showCenterMarkers' : { type: Boolean, default: true },
      'isSas': { type: Boolean, default: false },
      'mapCenter': { type: Object, default: function () {
          return {}
        }
      },
      'mapLocations': { type: Array, default: function () {
          return []
        }
      },
      'mapMarkers': { type: Array, default: function () {
          return []
        }
      },
      'mapSchool': { type: Object, default: function () {
          return {}
        }
      },
      'minimal': { type: Boolean, default: false },
      'showElement': { type: Boolean, default: true },
      'showLegend': { type: Boolean, default: false },
      'width': { type: String, required: true }
    },
    data() {
      return {
        geoJSONMarkers: [],
        geoJSONOptions: {
          //set center icon of each geojson layer
          onEachFeature: (feature, layer) => {
            const marker = {};
            const polygonCenter = layer.getBounds().getCenter();
            marker.center = polygonCenter;
            //layer.bindPopup(geomData.label);
            marker.icon = L.divIcon({
                className: 'map-div-icon geojson-marker',
                'html' : '<span>' + feature.name + '</span>'
            });
            this.geoJSONMarkers.push(marker)
          }
        },
        icon: L.icon({
          iconUrl: 'images/map-pin.png',
          iconSize: [28, 45],
          iconAnchor: [14, 45],
          className: 'location-marker'
        }),
        //latlngs array used to set bounds when all parts have loaded
        latlngs: [],
        leafletOptions: { 
          fullscreenControl: true
        },
        style: `${process.env.SITE_URL}/map/map-style-2019.json`,
        //zones used for map legend
        zones: [],
        zoom: 12
      }
    },
    methods: {
      makeZoneTheme: function(zone, index){
        const fill = colorHash.hex(zone.name + '-' + index.toString());
        const textColor = colorHash.hex(zone.name + '-' + (index+1).toString());
        const border = colorHash.hex(zone.name + '-' + (index+2).toString());
        return {
          fill: fill,
          textColor: textColor,
          border: border
        }
      },
      addMapboxLayerToLeafletMap: function(map) {
        //this worked better than using the vue-mapbox component
        const gl = L.mapboxGL({
          accessToken: process.env.VUE_APP_MAPBOX_TOKEN,
          style: this.style
        }).addTo(map);
      },
      setZoneThemes: function(locations) {
        let polygons = [];
        let zoneCounter = 0;
        
        polygons = locations.map((location) => {
          //set theme/color by zone
          if(location.hasOwnProperty('zones')) {
              return location.zones.map(zone => {
                zone.theme = this.makeZoneTheme(zone, zoneCounter)
                this.zones.push(zone)
                zoneCounter = zoneCounter + 3;
                //set geometry/outline for each zonepart/sector
                if(zone.hasOwnProperty('zoneparts')) {
                  return zone.zoneparts.map(zonepart => {
                    return this.setLayerStyle({
                      'label' : zonepart.name,
                      'theme' : zone.theme,
                      'geom' : isJson(zonepart.geom) ? JSON.parse(zonepart.geom) : zonepart.geom 
                    })
                  })
                }
              });
          } else { return []; }

        });
        return flattenDeep(polygons)
      },
      handleReady: function(map) {
        this.addMapboxLayerToLeafletMap(map);
      },
      setLayerStyle: function(layer) {
        layer.geom.style = {
          name: layer.geom.name,
          fillColor: layer.theme.fill,
          weight: 2,
          size: 15,
          opacity: 1,
          color: layer.theme.textColor,
          dashArray: '3',
          fillOpacity: .4
        };
        return layer;
      },
      mapSetZoom: function(map, zoom = null){
        if(zoom) {
          map.setZoom(zoom);
        } else {
          this.minimal ? map.setZoom(13) : map.setZoom(12);
        }
      },
    },
    mounted () {
    },
    beforeDestroy () {
      //remove all event listeners because vue2-leaflet doesnt do it properly
      this.leafletMap.off()
    },
    destroyed () {
    },
    created () {
    },
    computed: {
      // making these computed properties instead of part of the regular data object makes them reactive to changes in the angular state
      bounds: function() {
        if(this.latlngs.length > 1) {
          return latLngBounds(this.latlngs);
        } else {
          return null;
        }
      },
      center: function() {
        return this.mapCenter;
      },
      geoJSONLayers: function() {
        //each layer is a ZONEPART/SECTOR not a zone
        if(this.minimal || !this.locations.length) {
          return [];
        }
        
        let polygons = this.setZoneThemes(this.locations);
        
        let latlngs = [];
        let layers = [];
        if(polygons.length) {
          polygons.forEach(polygon => {
            polygon.geom.name = polygon.label;
            // loop over the coordinates array to expand the visible field of the map
            polygon.geom.coordinates.forEach(coords => {
              coords.forEach(coordinates => {
                if (coordinates.length !== 2) {
                  coordinates.forEach(point => {
                    latlngs.push(L.GeoJSON.coordsToLatLng(point));
                  })
                } else {
                  latlngs.push(L.GeoJSON.coordsToLatLng(coordinates));
                }
              })
            });
            // add geojson layer to array to show sectors/zones on map
            layers.push(polygon.geom)
          });
        }
        this.latlngs = latlngs;
        
        //remove duplicate zoneparts so they only get drawn once
        layers = Array.from(new Set(layers.map(layer => layer.name)))
        .map(name => {
          return layers.find(layer => layer.name === name)
        })
        return layers;
      },
      leafletMap: function() {
        return this.$refs.dcMap.mapObject;
      },
      locations: function() {
        return this.mapLocations;
      },
      zoneCenterMarkers: function() {

        const centroidMarkers = this.locations.map((location) => {
            return location.zones.map(zone => {
              const marker = L.latLng({lng: zone.centroid.coordinates[0], lat: zone.centroid.coordinates[1]});
              let html = 'Center of Zone ' + zone.name + '<br />';
                  html += '<p>This is for service are calculation</p>';
                  marker.popupData = html;

              return marker; //zone.centroid.coordinates;
            })
          })[0];

        let markers = this.locations.map(location => {
          if( isJson(location.address) ){
            location.address = JSON.parse(location.address);
          }
          const marker = L.latLng(location.lat, location.lng);
          let html = location.name + '<br />';
          html += location.address.street + '<br />';
          html += location.address.city + ' ';
          html += location.address.state + ' ';
          html += location.address.zip;
          marker.popupData = html;
          this.latlngs.push({lat: location.lat, lng: location.lng})
          return marker;
        });
        return markers; //[...markers,...centroidMarkers];
      },
      markers: function() {
        //this is used for the map-markers prop- will show a marker without the zone circle
        return this.mapMarkers.map((location) => {
          //let marker = L.marker([location.lat, location.lng]); //.addTo(mymap);
          const marker = L.latLng(location.lat, location.lng);
          let html = location.name + '<br />';
          html += location.address.street + '<br />';
          html += location.address.city + ' ';
          html += location.address.state + ' ';
          html += location.address.zip;
          marker.popupData = html;
          //marker.bindPopup(html).openPopup();
          return marker;
        })
      },
      school: function() {
        return this.mapSchool;
      },
    }
  }
</script>

<style lang="scss" scoped>
  .mapboxgl-map {
    width: 100%;
    height: 100%;
  }
</style>
