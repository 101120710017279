"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var InstructorCancelSessionController = /** @class */ (function () {
    function InstructorCancelSessionController($state, driveSession, DrivesModel, FlashService, $window) {
        this.$state = $state;
        this.DrivesModel = DrivesModel;
        this.FlashService = FlashService;
        this.$window = $window;
        this.drive = driveSession;
        this.student = null;
        if (this.drive.observation === false) {
            this.student_id = this.drive.student_id;
            this.students = [this.drive.student];
            this.student = this.drive.student;
        }
        else {
            this.students = this.drive.students;
        }
        this.cancellationReasons = [
            "Student no show",
            "Weather",
            "Vehicle related",
            "Other",
        ];
        this.submit = this.submit.bind(this);
        this.submitting = false;
        this.cancelData = {
            id: this.drive.id,
            cancelation_reason: "",
            cancelation_reason_explanation: "",
            student_id: null,
        };
        this.loading = false;
    }
    InstructorCancelSessionController.prototype.submit = function () {
        var _this = this;
        var confirmDelete = this.$window.confirm("Are you sure you want to cancel this session?");
        if (!confirmDelete) {
            return;
        }
        if (this.drive.observation === false) {
            this.cancelData.student_id = this.drive.student_id;
        }
        if (this.drive.observation === true) {
            this.cancelData.student_id = this.student.id;
        }
        if (this.cancelData.cancelation_reason_explanation === "") {
            delete this.cancelData.cancelation_reason_explanation;
        }
        this.submitting = true;
        this.DrivesModel.cancelDriveSession(this.cancelData).then(function (response) {
            _this.submitting = false;
            _this.FlashService.setMessage({
                type: "success",
                message: response.message,
            });
            _this.$state.go("drivecoach.instructor.dashboard");
        });
    };
    return InstructorCancelSessionController;
}());
InstructorCancelSessionController.$inject = [
    "$state",
    "driveSession",
    "DrivesModel",
    "FlashService",
    "$window",
];
exports.default = InstructorCancelSessionController;
