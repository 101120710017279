import angular from 'angular'
import template from './instructor.html'
import InstructorController from './instructor.controller'

import FooterNav from './directives/footernav/instructor-footer-nav.module'
import Nav from './directives/nav/instructor-nav.module'
import BatchCreate from './directives/batch-create-entity/batch-create-entity.module'

import Dashboard from './dashboard/instructor-dashboard.module'
import { createFromPolicyAPIResponse } from '../classes/Policies'
import { School } from '../classes/School'
import { Cashier } from '../classes/Cashier'
import { Order } from '../classes/Order'

function getCurrentUser(AuthService, UserService) {
    return AuthService.getCurrentUser().then(response => {
        UserService.setCurrentUser(response);
        return UserService.getCurrentUser();
    }).catch(Error => {
        console.log(Error)
    });
}
getCurrentUser.$inject = ['AuthService', 'UserService'];

function getPolicies(PoliciesModel, VueStoreFactory, CreateOrderService)
{
    return PoliciesModel.get().then(response => {
        VueStoreFactory.dispatch('SET_POLICIES', response);
        const policies = createFromPolicyAPIResponse(response);
        const school = new School({...response.school, policies: policies.toArray()});
        policies.setSchool(school);
        school.setPolicies(policies);
        const cashier = new Cashier(school, new Order());
        CreateOrderService.cashier(cashier);
        VueStoreFactory.dispatch('SET_SCHOOL', {...response.school, policies: policies.toArray()});

        return policies; 
    });
}
getPolicies.$inject = ['PoliciesModel', 'VueStoreFactory', 'CreateOrderService'];
function config($stateProvider, $urlRouterProvider, USER_ROLES){
    $stateProvider
        .state('drivecoach.instructor', {
            url: '/instructor',
            controller: 'InstructorController',
            controllerAs: 'vm',
            template: template,
            resolve : {
                currentUser: getCurrentUser,
                policies: getPolicies,
            },
            data: {
                authorizedRoles: [
                    USER_ROLES.admin,
                    USER_ROLES.owner,
                    USER_ROLES.instructor
                ]
            }
        })
    ;
}
config.$inject = ['$stateProvider', '$urlRouterProvider', 'USER_ROLES'];

export default angular.module('instructor', [
    Nav.name,
    FooterNav.name,
    Dashboard.name,
    BatchCreate.name,
])
    .config(config)
    .controller('InstructorController', InstructorController );
