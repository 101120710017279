class InstructorController {
    
    constructor($rootScope, CreateOrderService, currentUser, store, AUTH_EVENTS, policies) {
        this.user = currentUser;
        this.school = this.user.school;
        this.CreateOrderService = CreateOrderService;
        
        this.dsConfig = {
            'nav' : {
                'use_team_calendar' : false,
                'instructor_can_create_lessons' : false,
                'instructor_can_create_orders' : false,
            }
        }

        const DS_INSTRUCTOR_NAV_CONFIG = 'ds_instructor_nav_config';
        $rootScope.$on(AUTH_EVENTS.logoutSuccess, () => {
            store.remove(DS_INSTRUCTOR_NAV_CONFIG)
        });

        try {
            this.dsConfig.nav.use_team_calendar = policies.getPolicyByName('calendar').getMetaValueByKey('use_team_calendar');
        } catch (Error) {
            this.dsConfig.nav.use_team_calendar = false;
        }

        try {
            this.dsConfig.nav.instructor_can_create_lessons = policies.getPolicyByName('drives').getMetaValueByKey('instructor_can_create_lessons');
        } catch (Error) {
            this.dsConfig.nav.instructor_can_create_lessons = false;
        }

        try {
            this.dsConfig.nav.instructor_can_create_orders = policies.getPolicyByName('billing').getMetaValueByKey('instructor_can_create_orders');
        } catch (Error) {
            this.dsConfig.nav.instructor_can_create_orders = false;
        }
    }

    // for now just show the dashboard
    // $state.go('drivecoach.instructor.dashboard');
}

InstructorController.$inject = ['$rootScope', 'CreateOrderService', 'currentUser', 'store', 'AUTH_EVENTS', 'policies'];
export default InstructorController;