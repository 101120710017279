"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var time_1 = require("../../../common/helpers/time");
function AdminSettingsReportCardController($scope, $state, UserService, TasksModel, FlashService, SchoolsModel, GradeOptionModel, $q) {
    var vm = this;
    vm.currentUser = UserService.getCurrentUser();
    vm.school = vm.currentUser.school;
    vm.tasks = [];
    vm.selectedTasks = [];
    vm.addCustomTaskToReportCard = addCustomTaskToReportCard.bind(this);
    vm.addGradeOptionToReportCard = addGradeOptionToReportCard.bind(this);
    vm.removeGradeOption = removeGradeOption.bind(this);
    vm.addTaskToReportCard = addTaskToReportCard.bind(this);
    vm.removeTask = removeTask;
    vm.init = init.bind(this);
    init();
    function prepareDataForUpdate() {
        var tasks = Object.assign({}, vm.selectedTasks);
        return {
            'school_id': vm.currentUser.school_id,
            'tasks': tasks
        };
    }
    function addCustomTaskToReportCard() {
        var task = {
            'name': vm.customTask
        };
        vm.selectedTasks.push(task);
        var data = prepareDataForUpdate();
        SchoolsModel.updateTasks(data).then(function (response) {
            FlashService.setMessage({
                'type': 'success',
                'message': response.message
            });
            vm.customTask = null;
            vm.task = null;
        });
    }
    function addTaskToReportCard() {
        var task = {
            'name': vm.task.name
        };
        vm.selectedTasks.push(task);
        var data = prepareDataForUpdate();
        SchoolsModel.updateTasks(data).then(function (response) {
            FlashService.setMessage({
                'type': 'success',
                'message': response.message
            });
            vm.customTask = null;
            vm.task = null;
        });
    }
    function removeTask(index) {
        vm.selectedTasks.splice(index, 1);
        var data = prepareDataForUpdate();
        SchoolsModel.updateTasks(data).then(function (response) {
            FlashService.setMessage({
                'type': 'success',
                'message': response.message
            });
            vm.customTask = null;
            vm.task = null;
        });
    }
    function addGradeOptionToReportCard() {
        var gradeOption = {
            'option_name': vm.gradeOption.option_name
        };
        var data = Object.assign({}, gradeOption);
        GradeOptionModel.createGradeOption(data).then(function (response) {
            FlashService.setMessage({
                'type': 'success',
                'message': response.message
            });
            vm.gradeOptions.push(response.grade_option);
            vm.customTask = null;
            vm.task = null;
        });
    }
    function removeGradeOption(id, index) {
        vm.gradeOptions.splice(index, 1);
        if (id) {
            GradeOptionModel.removeGradeOption(id).then(function (response) {
                FlashService.setMessage({
                    'type': 'success',
                    'message': response.message
                });
                vm.customTask = null;
                vm.task = null;
            });
        }
    }
    function init() {
        vm.gradeOptions = [];
        vm.gradeOption = {
            option_name: "",
        };
        vm.tasks = [];
        vm.selectedTasks = [];
        $q.all([TasksModel.get(), GradeOptionModel.getGradeOptions(), SchoolsModel.getTasks(vm.currentUser.school_id)]).then(function (responses) {
            vm.tasks = responses[0].tasks.filter(function (task) {
                return task.approved === true;
            });
            vm.gradesCustomized = false;
            if (responses[1].grade_options.length > 0) {
                vm.gradeOptions = responses[1].grade_options;
                vm.gradesCustomized = true;
            }
            else {
                vm.gradeOptions = [
                    {
                        option_name: "Awesome job!",
                        option_value: "green",
                    },
                    {
                        option_name: "Good, but not great!",
                        option_value: "yellow",
                    },
                    {
                        option_name: "Needs work, keep practicing!",
                        option_value: "red",
                    },
                    {
                        option_name: "Not Covered",
                        option_value: "grey",
                    },
                ];
            }
            vm.selectedTasks = responses[2].tasks;
            var start_time = new Date();
            start_time.setDate((new Date().getDate() + 1));
            start_time.setHours(13, 0, 0, 0);
            var end_time = new Date();
            end_time.setDate((new Date().getDate() + 1));
            end_time.setHours(15, 0, 0, 0);
            vm.previewReportcard = {
                status: "Unlocked",
                drive: {
                    drive_date: (0, time_1.toYMD)(start_time),
                    start_time: start_time.toLocaleTimeString('en-US', { hour: 'numeric', 'minute': 'numeric' }),
                    session_length: 120,
                    instructor: {
                        firstname: "Certified",
                        lastname: "Instructor",
                        usermeta: [{ meta_key: 'license', meta_value: JSON.stringify({ 'number': 'LICENSE1234' }) }, { meta_key: '', meta_value: '' }]
                    },
                    vehicle: {
                        name: "Vehicle #1234"
                    }
                },
                type: {
                    name: "Graded"
                },
                tasks: vm.selectedTasks,
                grades: vm.selectedTasks.map(function (task) {
                    return {};
                })
            };
        });
    }
}
AdminSettingsReportCardController.$inject = ['$scope', '$state', 'UserService', 'TasksModel', 'FlashService', 'SchoolsModel', 'GradeOptionModel', '$q'];
exports.default = AdminSettingsReportCardController;
