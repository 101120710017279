import filter from 'lodash/filter'
import moment from 'moment'

class CreateReportCardController {

    constructor($state, $stateParams, DrivesModel, FlashService, ReportcardsModel, tasks, student, $window, GradeOptionModel, $q) {
        this.loading = true;
        this.$window = $window;
        this.$stateParams = $stateParams;
        this.drive = {};
        this.tasks = tasks.map(task => {
            task.grade = 'grey'
            return task;
        });

        this.gradeOptions = [
            {
                name: "Awesome job!",
                value: "green",
            },
            {
                name: "Good, but not great!",
                value: "yellow",
            },
            {
                name: "Needs work, keep practicing!",
                value: "red",
            },
            {
                name: "Not Covered",
                value: "grey",
            },
        ];

        $q.all([GradeOptionModel.getGradeOptions(), DrivesModel.getDriveByID($stateParams.driveId)]).then(responses => {
            if (responses[0].grade_options.length > 0) {
                this.gradeOptions = responses[0].grade_options.map(option => {
                    return {
                        name: option.option_name,
                        value: option.option_value
                    }
                });
            }

            this.drive = responses[1].drive;
            this.driveDate = moment(this.drive.drive_date + ' ' + this.drive.start_time);
            this.untilDrive = this.driveDate.fromNow();
            this.loading = false;
        });

        this.grades = [];
        this.gradeIncomplete = true;
        this.reportcard = {
            student: {
                firstname: student.firstname,
                lastname: student.lastname
            },
        };

        this.$state = $state;
        this.DrivesModel = DrivesModel;
        this.FlashService = FlashService;
        this.ReportcardsModel = ReportcardsModel;
    }

    getGrades(tasks) {
        let grades = [];
        tasks.forEach(task => {
            grades.push({
                'task_id': task.id,
                'student_id': this.reportcard.student_id,
                'name': task.name,
                'status': task.grade
            })
        });
        return grades;
    }

    submitGrades(isValid) {
        this.submitted = true;

        if (isValid) {
            const grades = this.getGrades(this.tasks);
            const data = {
                'drive_id': this.$stateParams.driveId,
                'grades': grades,
                'internal_note': this.internal_note,
                'external_note': this.external_note
            };

            this.ReportcardsModel.submitGrades(data).then(response => {
                this.FlashService.setMessage({
                    'type': 'success',
                    'message': response.message
                });
                this.submitted = false;
                this.$state.go('drivecoach.admin.reportcards');
            });

        } else {
            this.FlashService.setMessage({ 'type': 'error', 'message': 'Please complete the grades.' });
        }
    }

    checkGrade() {
        this.gradeIncomplete = filter(this.tasks, 'grade').length < this.tasks.length;
    }

    deleteReportCard(reportcard_id) {
        const confirm = this.$window.confirm(
            "Are you sure you want to delete this report card? THIS CANNOT BE UNDONE."
        );
        if (confirm === false) {
            return;
        }
        this.busy = true;
        this.ReportcardsModel.destroy({ id: reportcard_id })
            .then((response) => {
                this.busy = false;
                this.$state.go('drivecoach.admin.reportcards');
                this.FlashService.setMessage({
                    type: "success",
                    message: response.message,
                });
            })
            .catch((Error) => {
                this.busy = false;
            });
    }

}
CreateReportCardController.$inject = ['$state', '$stateParams', 'DrivesModel', 'FlashService', 'ReportcardsModel', 'tasks', 'student', '$window', 'GradeOptionModel', '$q'];

export default CreateReportCardController;
