import { inDollars } from "../../../DriveScout/Billing/Teller";

class StudentFeesController {

    constructor($stateParams, UsersModel, UserService){
        this.inDollars = inDollars.bind(this);
        this.$stateParams = $stateParams;
        this.student = UserService.getCurrentUser();
        this.UsersModel = UsersModel;
        this.fees = [];

        this.UsersModel.getPickupLocations(this.student.id).then(response => {
            this.addresses = response.pickuplocations.map(location => {
                return location.address;
            });
        });

        this.getFees();
    }

    getFees(){
        this.submitted = true;
        this.UsersModel.getStudentFees(this.student.id).then(response => {
            this.fees = response.fees;
            this.viewing = 'fees';
            this.submitted = false;
        });
    }

}
StudentFeesController.$inject = ['$stateParams', 'UsersModel', 'UserService'];
export default StudentFeesController;