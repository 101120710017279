import angular from 'angular'
import AdminStudentViewFeesController from './admin-student-fees.controller'
import template from './admin-student-fees.html'

import AdminPayStudentFee from './pay/admin-student-pay-fee.module'

function config($stateProvider, $urlRouterProvider, USER_ROLES) {
    $stateProvider
        .state('drivecoach.admin.students.view.fees', {
            'url': '/fees',
            'controller': AdminStudentViewFeesController,
            'controllerAs': 'vm',
            'template': template,
            data: {
                authorizedRoles: [
                    USER_ROLES.admin,
                    USER_ROLES.administrator,
                    USER_ROLES.owner,
                    USER_ROLES.staff
                ]
            }
        });
}
config.$inject = ['$stateProvider', '$urlRouterProvider', 'USER_ROLES'];

export default angular.module('admin.dashboard.students.view.fees', [
    AdminPayStudentFee.name
])
    .config(config)
    .controller('AdminStudentViewFeesController', AdminStudentViewFeesController);
