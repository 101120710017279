import { uniqBy, findIndex } from 'lodash'

class AdminInstructorEditLocation {

    constructor($state, $stateParams, UsersModel, FlashService, school, instructor, instructorLocations){
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.UsersModel = UsersModel;
        this.FlashService = FlashService;
        this.loading = true;

        this.zones = [];
        this.noSectorsSelected = true;
        this.formData = {
            'instructor_id' : $stateParams.instructorId,
            'sectors' : [],
        };

        this.school = school;
        this.center = {
            lat: this.school.address.lat,
            lng: this.school.address.lng,
            zoom: 34
        }

        this.instructor = instructor;
        this.locations = instructorLocations;

        // set the current location being viewed, this is where the instructor's zones come from
        this.locations.forEach(location => {
            if (parseInt(location.id) === parseInt($stateParams.locationId)) {
                this.formData.location = location;
                this.location = location;
            }
        });

        this.zones = this.location.zones.map(zone => {
            zone.zoneparts = zone.zoneparts.map(this.parseSector);
            return zone;
        });

        this.formData.sectors = this.instructor.zoneparts.map(this.parseSector);
        this.loading = false;
    }

    parseSector(sector){
        delete sector.geom;
        delete sector.created_at;
        delete sector.updated_at;
        delete sector.pivot;
        return sector;
    }

    submit( isValid ) {
        this.submitted = true;

        if ( isValid && this.formData.sectors.length > 0 ) {
            const payload = Object.assign({}, this.formData);
            let sectors = uniqBy(this.formData.sectors, 'id');
            payload.sectors = sectors;
            this.UsersModel.editInstructorLocation(payload).then( response => {
                this.FlashService.setMessage({ 'type' : 'success', 'message' : response.message });

                this.submitted = false;
                this.$state.go(
                    'drivecoach.admin.instructors.locations',
                    {'instructorId' : this.instructor.id},
                    {'reload' : true}
                );
            } );
        } else {
            this.FlashService.setMessage( { 'type' : 'error', 'message' : 'Please fill in the form.' } );
        }
    }

    handleClick(sector) {
        var index = findIndex(this.formData.sectors, {'id' : sector.id});
        // if sector is already selected and now being unchecked, remove from array
        if(this.isSelected(sector)) {
            this.formData.sectors.splice(index, 1)
        } else {
            this.formData.sectors.push(sector)
        }
    }

    isSelected(sector) {
        return findIndex(this.formData.sectors, {'id' : sector.id}) > -1;
    }
}

AdminInstructorEditLocation.$inject = ['$state', '$stateParams', 'UsersModel', 'FlashService', 'school', 'instructor', 'instructorLocations'];
export default AdminInstructorEditLocation;
